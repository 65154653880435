import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const SugaringPage = function () {
  return (
    <Layout>
      <Seo
        title="Body Sugaring Hair Removal"
        description="Find out the benefits of sugaring, and how it's different than traditional waxing"
      />
      <div className="relative h-32 bg-gray-900 md:h-28 lg:h-36" />
      <div className="relative  mx-auto max-w-md px-4 py-16 sm:max-w-4xl sm:px-6  lg:max-w-6xl lg:px-8">
        <h1 className="content-page-title pb-10">Body Sugaring</h1>
        <div className=" md:grid md:grid-cols-4 md:gap-10">
          <div>
            <h1 className="content-page-title">What is sugaring?</h1>
          </div>
          <div className="md:col-span-3">
            <p className="content-page-text">
              Sugaring is a form of hair removal. The sugar paste is made of
              sugar, lemon & water. The paste is applied to the skin in the
              opposite direction of hair growth and is removed in the direction
              of growth. The sugar is applied by hand at room temperature,
              eliminating the need for sticks & strips
            </p>
          </div>
        </div>
        <hr className="my-10" />
        <div className=" md:grid md:grid-cols-4 md:gap-10">
          <div>
            <h1 className="content-page-title">Benefits of sugaring</h1>
          </div>
          <div className="md:col-span-3">
            <ul className="content-page-text list-disc pl-4">
              <li>Natural ingredients</li>
              <li>
                Adheres to dead skin cells only, therefore exfoliating the skin
              </li>
              <li>Less abrasive than waxing</li>
              <li>No lingering pain or discomfort</li>
              <li>
                Sugar is heated to room temperature so no chance of burning
              </li>
              <li>Sugaring is safe during pregnancy</li>
              <li>
                Sugaring prevents & eliminates ingrown hairs as the sugar
                exfoliates and removes dead skin
              </li>
              <li>
                {' '}
                Eventually the hair follicle depletes so the hair come back
                thinner and fewer each time
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-10" />
        <div className=" md:grid md:grid-cols-4 md:gap-10">
          <div>
            <h1 className="content-page-title">Tips before your appointment</h1>
          </div>
          <div className="md:col-span-3">
            <ul className="content-page-text list-disc pl-4">
              <li>
                For your first time it’s recommended to have at least two weeks
                of growth, for legs it’s recommended three weeks.
              </li>
              <li>
                The hair must be at least the length of a grain of rice Do not
                exfoliate the day of your appointment.
              </li>
              <li>
                Numbing cream can be used. Apply in 1- 1 1/2 hours before your
                appointment
              </li>
              <li>
                Avoid wearing deodorant if you are getting underarms sugared or
                any lotions the day of your appointment
              </li>
              <li>
                If the hair is too long, please trim it before your appointment
              </li>

              <li>
                For males sugaring back or chest please bring a clean T-shirt
                with you
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-10" />
        <div className=" md:grid md:grid-cols-4 md:gap-10">
          <div>
            <h1 className="content-page-title">Aftercare</h1>
          </div>
          <div className="md:col-span-3">
            <ul className="content-page-text list-disc pl-4">
              <li>No tanning, exercise, or hot tubs for 24 hours</li>
              <li>
                Do not exfoliate for 48 hours after your appointment. It’s
                important that you exfoliate 3 times a week to eliminate ingrown
                hairs an ensure your skin stays soft & smooth
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SugaringPage;
